.javadoc {
  /*
 * Javadoc style sheet
 */

  /*
 * Styles for individual HTML elements.
 *
 * These are styles that are specific to individual HTML elements. Changing them affects the style of a particular
 * HTML element throughout the page.
 */

  body {
    background-color: #ffffff;
    color: #353833;
    font-family: "DejaVu Sans", Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  iframe {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    border: none;
  }
  a:link,
  a:visited {
    text-decoration: none;
  }
  a[href]:hover,
  a[href]:focus {
    text-decoration: none;
    color: #bb7a2a;
  }
  a[name] {
    color: #353833;
  }
  pre {
    font-family: "DejaVu Sans Mono", monospace;
    font-size: 14px;
  }
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 13px;
  }
  ul {
    list-style-type: disc;
  }
  code,
  tt {
    font-family: "DejaVu Sans Mono", monospace;
  }
  :not(h1, h2, h3, h4, h5, h6) > code,
  :not(h1, h2, h3, h4, h5, h6) > tt {
    font-size: 14px;
    padding-top: 4px;
    margin-top: 8px;
    line-height: 1.4em;
  }
  dt code {
    font-family: "DejaVu Sans Mono", monospace;
    font-size: 14px;
    padding-top: 4px;
  }
  .summary-table dt code {
    font-family: "DejaVu Sans Mono", monospace;
    font-size: 14px;
    vertical-align: top;
    padding-top: 4px;
  }
  sup {
    font-size: 8px;
  }
  button {
    font-family: "DejaVu Sans", Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  /*
 * Styles for HTML generated by javadoc.
 *
 * These are style classes that are used by the standard doclet to generate HTML documentation.
 */

  /*
 * Styles for document title and copyright.
 */
  .clear {
    clear: both;
    height: 0;
    overflow: hidden;
  }
  .about-language {
    float: right;
    padding: 0 21px 8px 8px;
    font-size: 11px;
    margin-top: -9px;
    height: 2.9em;
  }
  .legal-copy {
    margin-left: 0.5em;
  }
  .tab {
    background-color: #0066ff;
    color: #ffffff;
    padding: 8px;
    width: 5em;
    font-weight: bold;
  }
  /*
 * Styles for navigation bar.
 */
  @media screen {
    .flex-box {
      position: fixed;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    .flex-header {
      flex: 0 0 auto;
    }
    .flex-content {
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }
  .top-nav {
    background-color: #4d7a97;
    color: #ffffff;
    float: left;
    padding: 0;
    width: 100%;
    clear: right;
    min-height: 2.8em;
    padding-top: 10px;
    overflow: hidden;
    font-size: 12px;
  }
  .sub-nav {
    background-color: #dee3e9;
    float: left;
    width: 100%;
    overflow: hidden;
    font-size: 12px;
  }
  .sub-nav div {
    clear: left;
    float: left;
    padding: 0 0 5px 6px;
    text-transform: uppercase;
  }
  .sub-nav .nav-list {
    padding-top: 5px;
  }
  ul.nav-list {
    display: block;
    margin: 0 25px 0 0;
    padding: 0;
  }
  ul.sub-nav-list {
    float: left;
    margin: 0 25px 0 0;
    padding: 0;
  }
  ul.nav-list li {
    list-style: none;
    float: left;
    padding: 5px 6px;
    text-transform: uppercase;
  }
  .sub-nav .nav-list-search {
    float: right;
    margin: 0 0 0 0;
    padding: 5px 6px;
    clear: none;
  }
  .nav-list-search label {
    position: relative;
    right: -16px;
  }
  ul.sub-nav-list li {
    list-style: none;
    float: left;
    padding-top: 10px;
  }
  .top-nav a:link,
  .top-nav a:active,
  .top-nav a:visited {
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
  }
  .top-nav a:hover {
    text-decoration: none;
    color: #bb7a2a;
    text-transform: uppercase;
  }
  .nav-bar-cell1-rev {
    background-color: #f8981d;
    color: #253441;
    margin: auto 5px;
  }
  .skip-nav {
    position: absolute;
    top: auto;
    left: -9999px;
    overflow: hidden;
  }
  /*
 * Hide navigation links and search box in print layout
 */
  @media print {
    ul.nav-list,
    div.sub-nav {
      display: none;
    }
  }
  /*
 * Styles for page header and footer.
 */
  .title {
    margin: 10px 0;
  }
  .sub-title {
    margin: 5px 0 0 0;
  }
  .header ul {
    margin: 0 0 15px 0;
    padding: 0;
  }
  .header ul li,
  .footer ul li {
    list-style: none;
    font-size: 13px;
  }
  /*
 * Styles for headings.
 */
  body.class-declaration-page .summary h2,
  body.class-declaration-page .details h2,
  body.class-use-page h2,
  body.module-declaration-page .block-list h2 {
    font-style: italic;
    padding: 0;
    margin: 15px 0;
  }
  body.class-declaration-page .summary h3,
  body.class-declaration-page .details h3,
  body.class-declaration-page .summary .inherited-list h2 {
    background-color: #dee3e9;
    border: 1px solid #d0d9e0;
    margin: 0 0 6px -8px;
    padding: 7px 5px;
  }
  /*
 * Styles for page layout containers.
 */
  main {
    clear: both;
    padding: 10px 20px;
    position: relative;
  }
  dl.notes > dt {
    font-family: "DejaVu Sans", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0 0 0;
    // color: #4e4e4e;
  }
  dl.notes > dd {
    margin: 5px 10px 10px 0;
    font-size: 14px;
    // font-family: "DejaVu Serif", Georgia, "Times New Roman", Times, serif;
  }
  dl.name-value > dt {
    margin-left: 1px;
    font-size: 1.1em;
    display: inline;
    font-weight: bold;
  }
  dl.name-value > dd {
    margin: 0 0 0 1px;
    font-size: 1.1em;
    display: inline;
  }
  /*
 * Styles for lists.
 */
  li.circle {
    list-style: circle;
  }
  ul.horizontal li {
    display: inline;
    font-size: 0.9em;
  }
  div.inheritance {
    margin: 0;
    padding: 0;
  }
  div.inheritance div.inheritance {
    margin-left: 2em;
  }
  ul.block-list,
  ul.details-list,
  ul.member-list,
  ul.summary-list {
    margin: 10px 0 10px 0;
    padding: 0;
  }
  ul.block-list > li,
  ul.details-list > li,
  ul.member-list > li,
  ul.summary-list > li {
    list-style: none;
    margin-bottom: 15px;
    line-height: 1.4;
  }
  .summary-table dl,
  .summary-table dl dt,
  .summary-table dl dd {
    margin-top: 0;
    margin-bottom: 1px;
  }
  ul.see-list,
  ul.see-list-long {
    padding-left: 0;
    list-style: none;
  }
  ul.see-list li {
    display: inline;
  }
  ul.see-list li:not(:last-child):after,
  ul.see-list-long li:not(:last-child):after {
    content: ", ";
    white-space: pre-wrap;
  }
  /*
 * Styles for tables.
 */
  .summary-table,
  .details-table {
    width: 100%;
    border-spacing: 0;
    //border-left: 1px solid #eee;
    //border-right: 1px solid #eee;
    //border-bottom: 1px solid #eee;
    padding: 0;
  }
  .caption {
    position: relative;
    text-align: left;
    background-repeat: no-repeat;
    color: #253441;
    font-weight: bold;
    clear: none;
    overflow: hidden;
    padding: 0;
    padding-top: 10px;
    padding-left: 1px;
    margin: 0;
    white-space: pre;
  }
  .caption a:link,
  .caption a:visited {
    color: #1f389c;
  }
  .caption a:hover,
  .caption a:active {
    color: #ffffff;
  }
  .caption span {
    white-space: nowrap;
    padding-top: 5px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 7px;
    display: inline-block;
    float: left;
    background-color: #f8981d;
    border: none;
  }
  div.table-tabs {
    display: none;
    padding: 10px 0 0 1px;
    margin: 0;
  }
  div.table-tabs > button {
    border: none;
    cursor: pointer;
    padding: 5px 12px 7px 12px;
    font-weight: bold;
    margin-right: 3px;
  }
  div.table-tabs > button.active-table-tab {
    background: #f8981d;
    color: #253441;
  }
  div.table-tabs > button.table-tab {
    background: #4d7a97;
    color: #ffffff;
  }
  .two-column-summary {
    display: grid;
    grid-template-columns: minmax(15%, max-content) minmax(15%, auto);
  }
  .three-column-summary {
    display: grid;
    grid-template-columns: minmax(10%, max-content) minmax(15%, max-content) minmax(15%, auto);
  }
  .four-column-summary {
    display: grid;
    grid-template-columns: minmax(10%, max-content) minmax(10%, max-content) minmax(10%, max-content) minmax(10%, auto);
  }
  @media screen and (max-width: 600px) {
    .two-column-summary {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 800px) {
    .three-column-summary {
      display: grid;
      grid-template-columns: minmax(10%, max-content) minmax(25%, auto);
    }
    .three-column-summary .col-last {
      grid-column-end: span 2;
    }
  }
  @media screen and (max-width: 1000px) {
    .four-column-summary {
      display: grid;
      grid-template-columns: minmax(15%, max-content) minmax(15%, auto);
    }
  }
  .summary-table > div,
  .details-table > div {
    text-align: left;
    padding: 8px 3px 3px 7px;
  }
  .col-first,
  .col-second,
  .col-last,
  .col-constructor-name,
  .col-summary-item-name {
    vertical-align: top;
    padding-right: 0;
    padding-top: 8px;
    padding-bottom: 3px;
  }
  .table-header {
    // background: #dee3e9;
    font-weight: bold;
  }
  .col-first,
  .col-first {
    font-size: 13px;
  }
  .col-second,
  .col-second,
  .col-last,
  .col-constructor-name,
  .col-summary-item-name,
  .col-last {
    font-size: 13px;
  }
  .col-first,
  .col-second,
  .col-constructor-name {
    vertical-align: top;
    overflow: auto;
  }
  .col-last {
    white-space: normal;
  }
  .col-first a:link,
  .col-first a:visited,
  .col-second a:link,
  .col-second a:visited,
  .col-first a:link,
  .col-first a:visited,
  .col-second a:link,
  .col-second a:visited,
  .col-constructor-name a:link,
  .col-constructor-name a:visited,
  .col-summary-item-name a:link,
  .col-summary-item-name a:visited,
  .constant-values-container a:link,
  .constant-values-container a:visited,
  .all-classes-container a:link,
  .all-classes-container a:visited,
  .all-packages-container a:link,
  .all-packages-container a:visited {
    font-weight: bold;
  }
  .table-sub-heading-color {
    background-color: #eeeeff;
  }
  .even-row-color,
  .even-row-color .table-header {
    // background-color: #ffffff;
  }
  .odd-row-color,
  .odd-row-color .table-header {
    // background-color: #eeeeef;
  }
  /*
 * Styles for contents.
 */
  .deprecated-content {
    margin: 0;
    padding: 10px 0;
  }
  /*
  div.block {
    font-size: 14px;
    font-family: "DejaVu Serif", Georgia, "Times New Roman", Times, serif;
  }
  */
  .col-last div {
    padding-top: 0;
  }
  .col-last a {
    padding-bottom: 3px;
  }
  .module-signature,
  .package-signature,
  .type-signature,
  .member-signature {
    font-family: "DejaVu Sans Mono", monospace;
    font-size: 14px;
    margin: 14px 0;
    white-space: pre-wrap;
  }
  .module-signature,
  .package-signature,
  .type-signature {
    margin-top: 0;
  }
  .member-signature .type-parameters-long,
  .member-signature .parameters,
  .member-signature .exceptions {
    display: inline-block;
    vertical-align: top;
    white-space: pre;
  }
  .member-signature .type-parameters {
    white-space: normal;
  }
  /*
 * Styles for formatting effect.
 */
  .source-line-no {
    color: green;
    padding: 0 30px 0 0;
  }
  h1.hidden {
    visibility: hidden;
    overflow: hidden;
    font-size: 10px;
  }
  .block {
    display: block;
    margin: 0 10px 5px 0;
    // color: #474747;
  }
  .deprecated-label,
  .descfrm-type-label,
  .implementation-label,
  .member-name-label,
  .member-name-link,
  .module-label-in-package,
  .module-label-in-type,
  .override-specify-label,
  .package-label-in-type,
  .package-hierarchy-label,
  .type-name-label,
  .type-name-link,
  .search-tag-link,
  .preview-label {
    font-weight: bold;
  }
  .deprecation-comment,
  .help-footnote,
  .preview-comment {
    font-style: italic;
  }
  .deprecation-block {
    font-size: 14px;
    font-family: "DejaVu Serif", Georgia, "Times New Roman", Times, serif;
    border-style: solid;
    border-width: thin;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  .preview-block {
    font-size: 14px;
    font-family: "DejaVu Serif", Georgia, "Times New Roman", Times, serif;
    border-style: solid;
    border-width: thin;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
  }
  div.block div.deprecation-comment {
    font-style: normal;
  }
  /*
 * Styles specific to HTML5 elements.
 */
  main,
  nav,
  header,
  footer,
  section {
    display: block;
  }
  /*
 * Styles for javadoc search.
 */
  .ui-autocomplete-category {
    font-weight: bold;
    font-size: 15px;
    padding: 7px 0 7px 3px;
    background-color: #4d7a97;
    color: #ffffff;
  }
  .result-item {
    font-size: 13px;
  }
  .ui-autocomplete {
    max-height: 85%;
    max-width: 65%;
    overflow-y: scroll;
    overflow-x: scroll;
    white-space: nowrap;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }
  ul.ui-autocomplete {
    position: fixed;
    z-index: 999999;
  }
  ul.ui-autocomplete li {
    float: left;
    clear: both;
    width: 100%;
  }
  .result-highlight {
    font-weight: bold;
  }
  #search-input {
    background-size: 13px;
    background-repeat: no-repeat;
    background-position: 2px 3px;
    padding-left: 20px;
    position: relative;
    right: -18px;
    width: 400px;
  }
  #reset-button {
    background-color: rgb(255, 255, 255);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 0 none;
    width: 16px;
    height: 16px;
    position: relative;
    left: -4px;
    top: -4px;
    font-size: 0px;
  }
  .watermark {
    color: #545454;
  }
  .search-tag-desc-result {
    font-style: italic;
    font-size: 11px;
  }
  .search-tag-holder-result {
    font-style: italic;
    font-size: 12px;
  }
  .search-tag-result:target {
    background-color: yellow;
  }
  .module-graph span {
    display: none;
    position: absolute;
  }
  .module-graph:hover span {
    display: block;
    margin: -100px 0 0 100px;
    z-index: 1;
  }
  .inherited-list {
    margin: 10px 0 10px 0;
  }
  section.class-description {
    line-height: 1.4;
  }
  .summary section[class$="-summary"],
  .details section[class$="-details"],
  .class-uses .detail,
  .serialized-class-details {
    padding: 0px 20px 5px 10px;
    // border: 1px solid #ededed;
    // background-color: #f8f8f8;
  }
  .inherited-list,
  section[class$="-details"] .detail {
    padding: 0 0 5px 8px;
    // background-color: #ffffff;
    border: none;
  }
  .vertical-separator {
    padding: 0 5px;
  }
  ul.help-section-list {
    margin: 0;
  }
  ul.help-subtoc > li {
    display: inline-block;
    padding-right: 5px;
    font-size: smaller;
  }
  ul.help-subtoc > li::before {
    content: "\2022";
    padding-right: 2px;
  }
  span.help-note {
    font-style: italic;
  }
  /*
 * Indicator icon for external links.
 */
  main a[href*="://"]::after
  {
    content: "";
    display: inline-block;
    background-image: url('data:image/svg+xml; utf8, \
      <svg xmlns="http://www.w3.org/2000/svg" width="768" height="768">\
        <path d="M584 664H104V184h216V80H0v688h688V448H584zM384 0l132 \
        132-240 240 120 120 240-240 132 132V0z" fill="%234a6782"/>\
      </svg>');
    background-size: 100% 100%;
    width: 7px;
    height: 7px;
    margin-left: 2px;
    margin-bottom: 4px;
  }
  main a[href*="://"]:hover::after,
main a[href*="://"]:focus::after
  {
    background-image: url('data:image/svg+xml; utf8, \
      <svg xmlns="http://www.w3.org/2000/svg" width="768" height="768">\
        <path d="M584 664H104V184h216V80H0v688h688V448H584zM384 0l132 \
        132-240 240 120 120 240-240 132 132V0z" fill="%23bb7a2a"/>\
      </svg>');
  }

  /*
 * Styles for user-provided tables.
 *
 * borderless:
 *      No borders, vertical margins, styled caption.
 *      This style is provided for use with existing doc comments.
 *      In general, borderless tables should not be used for layout purposes.
 *
 * plain:
 *      Plain borders around table and cells, vertical margins, styled caption.
 *      Best for small tables or for complex tables for tables with cells that span
 *      rows and columns, when the "striped" style does not work well.
 *
 * striped:
 *      Borders around the table and vertical borders between cells, striped rows,
 *      vertical margins, styled caption.
 *      Best for tables that have a header row, and a body containing a series of simple rows.
 */

  table.borderless,
  table.plain,
  table.striped {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  table.borderless > caption,
  table.plain > caption,
  table.striped > caption {
    font-weight: bold;
    font-size: smaller;
  }
  table.borderless th,
  table.borderless td,
  table.plain th,
  table.plain td,
  table.striped th,
  table.striped td {
    padding: 2px 5px;
  }
  table.borderless,
  table.borderless > thead > tr > th,
  table.borderless > tbody > tr > th,
  table.borderless > tr > th,
  table.borderless > thead > tr > td,
  table.borderless > tbody > tr > td,
  table.borderless > tr > td {
    border: none;
  }
  table.borderless > thead > tr,
  table.borderless > tbody > tr,
  table.borderless > tr {
    background-color: transparent;
  }
  table.plain {
    border-collapse: collapse;
    border: 1px solid black;
  }
  table.plain > thead > tr,
  table.plain > tbody tr,
  table.plain > tr {
    background-color: transparent;
  }
  table.plain > thead > tr > th,
  table.plain > tbody > tr > th,
  table.plain > tr > th,
  table.plain > thead > tr > td,
  table.plain > tbody > tr > td,
  table.plain > tr > td {
    border: 1px solid black;
  }
  table.striped {
    border-collapse: collapse;
    border: 1px solid black;
  }
  table.striped > thead {
    background-color: #e3e3e3;
  }
  table.striped > thead > tr > th,
  table.striped > thead > tr > td {
    border: 1px solid black;
  }
  table.striped > tbody > tr:nth-child(even) {
    background-color: #eee;
  }
  table.striped > tbody > tr:nth-child(odd) {
    background-color: #fff;
  }
  table.striped > tbody > tr > th,
  table.striped > tbody > tr > td {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  table.striped > tbody > tr > th {
    font-weight: normal;
  }
  /**
 * Tweak font sizes and paddings for small screens.
 */
  @media screen and (max-width: 1050px) {
    #search-input {
      width: 300px;
    }
  }
  @media screen and (max-width: 800px) {
    #search-input {
      width: 200px;
    }
    .top-nav,
    .bottom-nav {
      font-size: 11px;
      padding-top: 6px;
    }
    .sub-nav {
      font-size: 11px;
    }
    .about-language {
      padding-right: 16px;
    }
    ul.nav-list li,
    .sub-nav .nav-list-search {
      padding: 6px;
    }
    ul.sub-nav-list li {
      padding-top: 5px;
    }
    main {
      padding: 10px;
    }
    .summary section[class$="-summary"],
    .details section[class$="-details"],
    .class-uses .detail,
    .serialized-class-details {
      padding: 0 8px 5px 8px;
    }
    body {
      -webkit-text-size-adjust: none;
    }
  }
  @media screen and (max-width: 500px) {
    #search-input {
      width: 150px;
    }
    .top-nav,
    .bottom-nav {
      font-size: 10px;
    }
    .sub-nav {
      font-size: 10px;
    }
    .about-language {
      font-size: 10px;
      padding-right: 12px;
    }
  }
  .disabled {
    pointer-events: none;
  }
  .dark-mode & a {
    color: #92a8ca !important;
  }
  .light-mode & a {
    color: #ff9900 !important;
  }
}
