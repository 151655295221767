body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}
code {
  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 &,
  h6 & {
    font-size: inherit;
    color: inherit !important;
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
span.success {
  color: lightgreen;
}
span.failure {
  color: lightcoral;
}
div.MuiAlert-message {
  p:last-child {
    margin-bottom: 0;
  }
}
sup.footnote {
  vertical-align: top;
  font-size: 0.7em;
  span.inner:hover {
    text-decoration: underline;
  }
}
.tooltip-container {
  color: #555555 !important;
  background-color: #fefefe !important;
  .dark-mode & {
    background-color: #333333 !important;
    color: #cccccc !important;
  }
  @media print {
    color: #555555 !important;
    background-color: #fefefe !important;
  }
  font-size: 0.8em;
  max-width: 20em;
}
.screenonly {
  display: none;
  @media screen {
    display: block;
  }
}
.printonly {
  display: none;
  @media print {
    display: block;
  }
}
a {
  &.anchor {
    color: inherit !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media print {
    text-decoration: none;
    &:hover {
      text-decoration: underline !important;
    }
    color: darkblue !important;
    &.anchor {
      color: inherit !important;
    }
  }
}
a.anchorTarget {
  display: block;
  visibility: hidden;
  position: relative;
  top: -80px;
}
div.anchorTarget {
  display: block;
  visibility: hidden;
  position: relative;
  top: -80px;
}
@media print {
  a.anchorTarget {
    display: block;
    visibility: hidden;
    position: relative;
    top: 0px;
  }
}
.yt-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 360px;
}
.yt-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
table.homeworkTable {
  td {
    font-size: 0.95rem !important;
    margin: 0px;
    padding: 4px 8px;
  }
  th {
    margin: 0px;
    padding: 4px 8px;
  }
}
