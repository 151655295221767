.kotlindoc {
  .dark-mode & a {
    color: #92a8ca !important;
  }
  .light-mode & a {
    color: #ff9900 !important;
  }
  .disabled {
    pointer-events: none;
    text-decoration: none !important;
  }
  .parameterName,
  .keyword {
    font-weight: bold;
  }
  .signature {
    border: 1px solid lightgrey;
    padding: 8px;
    margin-top: 8px;
  }
}
