.ace_gutter {
  width: 19px !important;
}
.ace_static_highlight .ace_gutter {
  text-align: right;
  padding: 0 12px 0 0;
  margin-right: 3px;
  contain: none;
}
.ace_static_highlight.ace_show_gutter .ace_line {
  padding-left: 23px;
}
.staticcontent .ace_line {
  white-space: pre;
}
.ace_static_highlight .ace_line {
  position: relative;
}
.ace_static_highlight .ace_gutter-cell {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.ace_static_highlight .ace_gutter-cell:before {
  content: counter(ace_line, decimal);
  counter-increment: ace_line;
}
.ace_static_highlight {
  counter-reset: ace_line;
}
.ace_editor {
  font-size: 1rem;
  line-height: 1.375;
  white-space: pre-wrap;
}
.staticcontent.ace_editor {
  overflow: hidden;
}

.ace_editor.ace-ssr {
  background-color: transparent !important;
  color: #555555;

  .ace_gutter {
    background: #f6f6f6;
    color: #4d4d4c;
  }
  .ace_print-margin {
    width: 1px;
    background: #f6f6f6;
  }

  .ace_cursor {
    color: #aeafad;
  }

  .ace_marker-layer .ace_selection {
    background: #d6d6d6;
  }

  &.ace_multiselect .ace_selection.ace_start {
    box-shadow: 0 0 3px 0px #ffffff;
  }

  .ace_marker-layer .ace_step {
    background: rgb(255, 255, 0);
  }

  .ace_marker-layer .ace_bracket {
    margin: -1px 0 0 -1px;
    border: 1px solid #d1d1d1;
  }

  .ace_marker-layer .ace_active-line {
    background: #efefef;
  }

  .ace_gutter-active-line {
    background-color: #dcdcdc;
  }

  .ace_marker-layer .ace_selected-word {
    border: 1px solid #d6d6d6;
  }

  .ace_invisible {
    color: #d1d1d1;
  }

  .ace_keyword,
  .ace_meta,
  .ace_storage,
  .ace_storage.ace_type,
  .ace_support.ace_type {
    color: #8959a8;
  }

  .ace_keyword.ace_operator {
    color: #3e999f;
  }

  .ace_constant.ace_character,
  .ace_constant.ace_language,
  .ace_constant.ace_numeric,
  .ace_keyword.ace_other.ace_unit,
  .ace_support.ace_constant,
  .ace_variable.ace_parameter {
    color: #f5871f;
  }

  .ace_constant.ace_other {
    color: #666969;
  }

  .ace_invalid {
    color: #ffffff;
    background-color: #c82829;
  }

  .ace_invalid.ace_deprecated {
    color: #ffffff;
    background-color: #8959a8;
  }

  .ace_fold {
    background-color: #4271ae;
    border-color: #4d4d4c;
  }

  .ace_entity.ace_name.ace_function,
  .ace_support.ace_function,
  .ace_variable {
    color: #4271ae;
  }

  .ace_support.ace_class,
  .ace_support.ace_type {
    color: #c99e00;
  }

  .ace_heading,
  .ace_markup.ace_heading,
  .ace_string {
    color: #718c00;
  }

  .ace_entity.ace_name.ace_tag,
  .ace_entity.ace_other.ace_attribute-name,
  .ace_meta.ace_tag,
  .ace_string.ace_regexp,
  .ace_variable {
    color: #c82829;
  }

  .ace_comment {
    color: #8e908c;
  }

  .ace_indent-guide {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bdu3f/BwAlfgctduB85QAAAABJRU5ErkJggg==)
      right repeat-y;
  }

  .ace_marker-layer .ace_selected-word {
    background: rgb(250, 250, 255);
  }

  .dark-mode & {
    background-color: #333333;
    color: #cccccc;

    .ace_gutter {
      background: #25282c;
      color: #c5c8c6;
    }

    .ace_print-margin {
      width: 1px;
      background: #25282c;
    }

    .ace_cursor {
      color: #aeafad;
    }

    .ace_marker-layer .ace_selection {
      background: #373b41;
    }

    &.ace_multiselect .ace_selection.ace_start {
      box-shadow: 0 0 3px 0px #1d1f21;
    }

    .ace_marker-layer .ace_step {
      background: rgb(102, 82, 0);
    }

    .ace_marker-layer .ace_bracket {
      margin: -1px 0 0 -1px;
      border: 1px solid #4b4e55;
    }

    .ace_marker-layer .ace_active-line {
      background: #282a2e;
    }

    .ace_gutter-active-line {
      background-color: #282a2e;
    }

    .ace_marker-layer .ace_selected-word {
      border: 1px solid #373b41;
    }

    .ace_invisible {
      color: #4b4e55;
    }

    .ace_keyword,
    .ace_meta,
    .ace_storage,
    .ace_storage.ace_type,
    .ace_support.ace_type {
      color: #b294bb;
    }

    .ace_keyword.ace_operator {
      color: #8abeb7;
    }

    .ace_constant.ace_character,
    .ace_constant.ace_language,
    .ace_constant.ace_numeric,
    .ace_keyword.ace_other.ace_unit,
    .ace_support.ace_constant,
    .ace_variable.ace_parameter {
      color: #de935f;
    }

    .ace_constant.ace_other {
      color: #ced1cf;
    }

    .ace_invalid {
      color: #ced2cf;
      background-color: #df5f5f;
    }

    .ace_invalid.ace_deprecated {
      color: #ced2cf;
      background-color: #b798bf;
    }

    .ace_fold {
      background-color: #81a2be;
      border-color: #c5c8c6;
    }

    .ace_entity.ace_name.ace_function,
    .ace_support.ace_function,
    .ace_variable {
      color: #81a2be;
    }

    .ace_support.ace_class,
    .ace_support.ace_type {
      color: #f0c674;
    }

    .ace_heading,
    .ace_markup.ace_heading,
    .ace_string {
      color: #b5bd68;
    }

    .ace_entity.ace_name.ace_tag,
    .ace_entity.ace_other.ace_attribute-name,
    .ace_meta.ace_tag,
    .ace_string.ace_regexp,
    .ace_variable {
      color: #cc6666;
    }

    .ace_comment {
      color: #969896;
    }

    .ace_indent-guide {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAEklEQVQImWNgYGBgYHB3d/8PAAOIAdULw8qMAAAAAElFTkSuQmCC)
        right repeat-y;
    }
    .ace_marker-layer .ace_selected-word {
      background: #373b41;
    }
  }
}
.ace_gutter-cell {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 8px !important;
  font-size: 0.8em;
}
.ace_gutter-cell.ace_info {
  background-image: none !important;
  background-color: lightblue;
  .dark-mode & {
    background-color: darkblue;
  }
}
.ace_display_only .ace_cursor-layer {
  display: none;
}
.ace_display_only .ace_bracket {
  display: none;
}
.ace_display_only .ace_indent-guide {
  background: none;
}
.ace_mobile-menu {
  display: none !important;
}
.ace_fold-widget {
  display: none !important;
}

// From Ace.tsx

.ace_gutter {
  background: none !important;
}
.ace_display_only .ace_cursor-layer {
  display: none;
}
.ace_display_only .ace_bracket {
  display: none;
}
.ace_display_only .ace_indent-guide {
  background: none;
}
.ace_mobile-menu {
  display: none !important;
}
.ace_fold-widget {
  display: none !important;
}
